import { ACTIONS } from 'src/components/TariffPlans/actions';
import { useSendConfigToConsole } from 'src/components/TariffPlans/hooks/useSendConfigToConsole';
import {
  useTariffPlansDispatch,
  useTariffPlansState,
} from 'src/components/TariffPlans/tariff-plans-context';
import {
  CurrentPricePeriod,
  PlanEvoCloudServer,
  PlanIdCloudServer,
  PlansSwiperProps,
} from 'src/components/TariffPlans/types';

import { CalcCard } from '../../components/CalcCard';
import { PlanCard } from '../../components/PlanCard';
import { TariffSwiper } from '../../components/TariffSwiper';
import { useUpdatePlanCardPrice } from '../../hooks/useUpdatePlanCardPrice';

interface PlanCardProps {
  planId: PlanIdCloudServer;
  price: number;
  description: string;
  guaranteedPart: PlanEvoCloudServer['data']['guaranteedPart'];
  vCpuCoreCount: PlanEvoCloudServer['data']['vCpuCoreCount'];
  ramAmount: PlanEvoCloudServer['data']['ramAmount'];
  systemDiskDiskSpace: PlanEvoCloudServer['data']['evs']['systemDisk']['diskSpace'];
  networkIsNeeded: PlanEvoCloudServer['data']['networkIsNeeded'];
  onClickNetworkIsNeeded: () => void;
  onClickOnConnect: () => void;
  currentPricePeriod: CurrentPricePeriod;
}

function EvoCloudServerPlanCard({
  planId,
  price,
  description,
  guaranteedPart,
  vCpuCoreCount,
  ramAmount,
  systemDiskDiskSpace,
  networkIsNeeded,
  onClickNetworkIsNeeded,
  onClickOnConnect,
  currentPricePeriod,
}: PlanCardProps) {
  const { planState } = useUpdatePlanCardPrice({
    planId,
    priceActionType: ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_PRICE,
    statusActionType: ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_STATUS,
    productType:
      planId === 'free-tier'
        ? 'evolutionCloudServerFreeTier'
        : 'evolutionCloudServer',
  });

  const characteristics = [
    {
      title: 'Гарантированная доля vCPU',
      value:
        planId === 'free-tier'
          ? `Доля до ${guaranteedPart}%`
          : `${guaranteedPart}% доля`,
    },
    {
      title: 'Количество vCPU, шт',
      value: `${vCpuCoreCount} vCPU`,
    },
    {
      title: 'Объем RAM',
      value: `${ramAmount} ГБ`,
    },
    {
      title: 'Диск NVMe',
      value: `${systemDiskDiskSpace} ГБ`,
    },
  ];

  return (
    <PlanCard
      productType={
        planId === 'free-tier'
          ? 'evolutionCloudServerFreeTier'
          : 'evolutionCloudServer'
      }
      isFreeTier={planId === 'free-tier'}
      planStatus={planState?.planStatus}
      description={description}
      currentPricePeriod={currentPricePeriod}
      price={price}
      characteristics={characteristics}
      onClickOnConnect={onClickOnConnect}
      onCheckboxClick={onClickNetworkIsNeeded}
      checkBoxText="Добавить IP-адрес"
      checkBoxActive={networkIsNeeded}
      tooltipText="4 000 бонусных рублей"
      tooltipHint="Получите 4000 бонусных рублей на 60 дней за привязку банковской карты"
      freeTierTooltip="Бесплатно предоставляем виртуальную машину в заданной конфигурации для создания, тестирования и развертывания приложений. Любые дополнительные сервисы, такие как Публичный IP адрес, оплачиваются согласно тарифам платформы."
    />
  );
}

function EvoCloudServerPlansSwiper({ isProductPage }: PlansSwiperProps) {
  const dispatch = useTariffPlansDispatch();
  const state = useTariffPlansState();

  const { connectProduct } = useSendConfigToConsole();

  const slides = state.plans[state.tariffPlansType].evolutionCloudServer.map(
    (plan) => {
      return (
        <EvoCloudServerPlanCard
          key={plan.planId}
          planId={plan.planId}
          price={plan.price[state.currentPricePeriod]}
          description={plan.description}
          guaranteedPart={plan.data.guaranteedPart}
          vCpuCoreCount={plan.data.vCpuCoreCount}
          ramAmount={plan.data.ramAmount}
          systemDiskDiskSpace={plan.data.evs.systemDisk.diskSpace}
          networkIsNeeded={plan.data.networkIsNeeded}
          onClickNetworkIsNeeded={() =>
            dispatch({
              type: ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_INPUTS,
              payload: {
                planId: plan.planId,
                data: {
                  networkIsNeeded: !plan.data.networkIsNeeded,
                },
              },
            })
          }
          onClickOnConnect={() =>
            connectProduct(
              plan.planId === 'free-tier'
                ? 'evolutionCloudServerFreeTier'
                : 'evolutionCloudServer',
              plan,
            )
          }
          currentPricePeriod={state.currentPricePeriod}
        />
      );
    },
  );

  return (
    <TariffSwiper
      slides={[
        ...slides,
        <CalcCard
          key="card-calculator"
          planIndex={slides.length}
          product="evolutionCloudServer"
        />,
      ]}
      isProduct={isProductPage}
    />
  );
}

export { EvoCloudServerPlansSwiper };
