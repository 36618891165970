import { themeVars } from '@sbercloud/figma-tokens-web';
import { ButtonFunction } from '@snack-uikit/button';
import { Divider } from '@snack-uikit/divider';
import { ChevronRightSVG } from '@snack-uikit/icons';
import { List } from '@snack-uikit/list';
import { Tabs } from '@snack-uikit/tabs';
import { Typography } from '@snack-uikit/typography';
import { useRouter } from 'next/navigation';
import { ReactNode, useState } from 'react';
import { ACTIONS } from 'src/components/TariffPlans/actions';
import {
  useTariffPlansDispatch,
  useTariffPlansState,
} from 'src/components/TariffPlans/tariff-plans-context';
import { useAnalytics } from 'src/hooks/useAnalytics';

import s from './ProductChanger.module.scss';

type ListItemType = {
  title: string;
  desc: string;
  link: string;
  icon: string;
  id: ProductTabID;
};

enum ProductTabID {
  EvoCloudServerMainPage = 'evoCloudServerMainPage',
  EvoS3MainPage = 'evoS3MainPage',
  EvoKuberMainPage = 'evoKuberMainPage',
  EvoPostgreSqlMainPage = 'evoPostgreSqlMainPage',
  EvoBareMetalMainPage = 'evoBareMetalMainPage',
  EvoContainerAppsMainPage = 'evoContainerAppsMainPage',
}

const mobileTabs: {
  id: ProductTabID;
  content: { option: string };
}[] = [
  {
    id: ProductTabID.EvoCloudServerMainPage,
    content: { option: 'Evolution Compute' },
  },
  {
    id: ProductTabID.EvoS3MainPage,
    content: { option: 'Evolution Object Storage' },
  },
  {
    id: ProductTabID.EvoKuberMainPage,
    content: { option: 'Evolution Managed Kubernetes' },
  },
  {
    id: ProductTabID.EvoPostgreSqlMainPage,
    content: { option: 'Evolution Managed PostgreSQL' },
  },
  {
    id: ProductTabID.EvoBareMetalMainPage,
    content: { option: 'Evolution Bare Metal' },
  },
  // {
  //   id: ProductTabID.EvoContainerAppsMainPage,
  //   content: { option: 'Evolution Container Apps' },
  // },
];

const products: {
  id: ProductTabID;
  content: ReactNode;
  slug: ProductTabID;
}[] = [
  {
    id: ProductTabID.EvoCloudServerMainPage,
    content: (
      <ListItem
        icon="https://cdn.cloud.ru/backend/products/evo-compute/icon.svg"
        title="Evolution Compute"
        desc="Виртуальные машины для развертывания сервисов и приложений"
        link="/products/evolution-compute"
        id={ProductTabID.EvoCloudServerMainPage}
      />
    ),
    slug: ProductTabID.EvoCloudServerMainPage,
  },
  {
    id: ProductTabID.EvoS3MainPage,
    content: (
      <ListItem
        icon="https://cdn.cloud.ru/backend/products/evo-object-storage/icon_2_d.svg"
        title="Evolution Object Storage"
        desc="Масштабируемое хранилище S3 для всех типов данных"
        link="/products/evolution-object-storage"
        id={ProductTabID.EvoS3MainPage}
      />
    ),
    slug: ProductTabID.EvoS3MainPage,
  },
  {
    id: ProductTabID.EvoKuberMainPage,
    content: (
      <ListItem
        icon="https://cdn.cloud.ru/backend/products/evo-managed-kubernetes/icon.svg"
        title="Evolution Managed Kubernetes"
        desc="Управление контейнерными приложениями в кластере Kubernetes"
        link="/products/evolution-managed-kubernetes"
        id={ProductTabID.EvoKuberMainPage}
      />
    ),
    slug: ProductTabID.EvoKuberMainPage,
  },
  {
    id: ProductTabID.EvoPostgreSqlMainPage,
    content: (
      <ListItem
        icon="https://cdn.cloud.ru/backend/products/evolution-managed-postgresql/icon_2_d.svg"
        title="Evolution Managed PostgreSQL"
        desc="Управляемые базы данных PostgreSQL"
        link="/products/evolution-managed-postgresql"
        id={ProductTabID.EvoPostgreSqlMainPage}
      />
    ),
    slug: ProductTabID.EvoPostgreSqlMainPage,
  },
  {
    id: ProductTabID.EvoBareMetalMainPage,
    content: (
      <ListItem
        icon="https://cdn.cloud.ru/backend/products/evolution-bare-metal/icon_2_d.svg"
        title="Evolution Bare Metal"
        desc="Сервис аренды выделенных физических серверов с доступом и управлением только для вас"
        link="/products/evolution-bare-metal"
        id={ProductTabID.EvoBareMetalMainPage}
      />
    ),
    slug: ProductTabID.EvoBareMetalMainPage,
  },
  // {
  //   id: ProductTabID.EvoContainerAppsMainPage,
  //   content: (
  //     <ListItem
  //       icon="https://cdn.cloud.ru/backend/products/evolution-container-apps/icon_2_d.svg"
  //       title="Evolution Container Apps"
  //       desc="Сервис для запуска контейнерных приложений в облаке"
  //       link="/products/evolution-container-apps"
  //       id={ProductTabID.EvoContainerAppsMainPage}
  //     />
  //   ),
  //   slug: ProductTabID.EvoContainerAppsMainPage,
  // },
];

function getProductNameForYm(productType: ProductTabID) {
  switch (productType) {
    case ProductTabID.EvoCloudServerMainPage:
      return 'Evo-cloud-server';
    case ProductTabID.EvoKuberMainPage:
      return 'Evo-kubernetes';
    case ProductTabID.EvoS3MainPage:
      return 'Evo-storageS3';
    default:
      return '';
  }
}

function ListItem({ title, desc, icon, link, id }: ListItemType) {
  const router = useRouter();
  const { sendAnalytics } = useAnalytics();

  const handleMoreClick = () => {
    const productNameForYm = getProductNameForYm(id);

    if (productNameForYm) {
      const ymData = {
        'Tariff-plan-connect': {
          [productNameForYm]: {
            [window.location.href.split('?')[0]]: {
              Navigation: 'details-click',
            },
          },
        },
      };
      sendAnalytics({}, ymData, 'Tariff-plan-connect');
    }

    router.push(link);
  };
  const state = useTariffPlansState();
  const currentPlan = state.activePlansSet;
  const active = currentPlan === id;

  return (
    <div className={s.item}>
      <img src={icon} alt="calculator" className={s.img} />
      <div className={s.info}>
        <div className={s.text}>
          {active ? (
            <Typography.SansTitleM className={s.title}>
              {title}
            </Typography.SansTitleM>
          ) : (
            <Typography.SansBodyL className={s.title}>
              {title}
            </Typography.SansBodyL>
          )}

          {active && (
            <Typography.SansBodyM className={s.desc}>
              {desc}
            </Typography.SansBodyM>
          )}
        </div>
        {active && (
          <ButtonFunction
            label="Подробнее"
            onClick={handleMoreClick}
            icon={<ChevronRightSVG color={themeVars.sys.neutral.textLight} />}
            iconPosition="after"
          />
        )}
      </div>
    </div>
  );
}

function ProductChanger() {
  const dispatch = useTariffPlansDispatch();
  const { sendAnalytics } = useAnalytics();
  const [tabId, setTabId] = useState<ProductTabID>(
    ProductTabID.EvoCloudServerMainPage,
  );

  const handleChange = (value: string) => {
    if (tabId === value || !value) return;

    const productNameForYm = getProductNameForYm(value as ProductTabID);

    if (productNameForYm) {
      const ymData = {
        'Tariff-plan-connect': {
          [productNameForYm]: {
            [window.location.href.split('?')[0]]: {
              Navigation: 'tab-click',
            },
          },
        },
      };
      sendAnalytics({}, ymData, 'Tariff-plan-connect');
    }

    setTabId(value as ProductTabID);
    dispatch({
      type: ACTIONS.UPD_ACTIVE_PLANS_SET,
      payload: value as ProductTabID,
    });
  };

  return (
    <div className={s.products}>
      <Divider orientation="vertical" className={s.divider} />
      <List
        size="m"
        items={products}
        selection={{
          value: tabId,
          onChange: handleChange,
          mode: 'single',
        }}
        scroll
        className={s.list}
      />
      <div className={s.tabs}>
        <Tabs onChange={handleChange} value={tabId}>
          <Tabs.TabBar>
            {mobileTabs.map((item) => (
              <Tabs.Tab
                key={item.id}
                value={item.id}
                label={item.content.option}
              />
            ))}
          </Tabs.TabBar>
        </Tabs>
      </div>
    </div>
  );
}

export { ProductChanger };
