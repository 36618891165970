import { ACTIONS } from 'src/components/TariffPlans/actions';
import { useSendConfigToConsole } from 'src/components/TariffPlans/hooks/useSendConfigToConsole';
import { useTariffPlansState } from 'src/components/TariffPlans/tariff-plans-context';
import {
  CurrentPricePeriod,
  PlanEvoContainerApps,
  PlansSwiperProps,
} from 'src/components/TariffPlans/types';

import { CalcCard } from '../../components/CalcCard';
import { PlanCard } from '../../components/PlanCard';
import { TariffSwiper } from '../../components/TariffSwiper';
import { useUpdatePlanCardPrice } from '../../hooks/useUpdatePlanCardPrice';

interface PlanCardProps {
  plan: PlanEvoContainerApps;
  price: number;
  description: string;
  onClickOnConnect: () => void;
  currentPricePeriod: CurrentPricePeriod;
}

function EvoContainerAppsPlanCard({
  plan,
  price,
  description,
  onClickOnConnect,
  currentPricePeriod,
}: PlanCardProps) {
  const {
    planId,
    data: { workingHours, config },
  } = plan;

  const { planState } = useUpdatePlanCardPrice({
    planId,
    priceActionType: ACTIONS.UPD_PLAN_EVOLUTION_CONTAINER_APPS_PRICE,
    statusActionType: ACTIONS.UPD_PLAN_EVOLUTION_CONTAINER_APPS_STATUS,
    productType: 'evolutionContainerApps',
  });

  const [vCpu, ram] = config.split(' ');

  const characteristics = [
    {
      title: 'Количество ядер vCPU',
      value: vCpu,
    },
    {
      title: 'Количество оперативной памяти (RAM)',
      value: ram,
    },
    {
      title: 'Время работы в месяц (Часов)',
      value: `${workingHours}`,
    },
  ];

  return (
    <PlanCard
      productType="evolutionContainerApps"
      description={description}
      planStatus={planState?.planStatus}
      currentPricePeriod={currentPricePeriod}
      price={price}
      characteristics={characteristics}
      onClickOnConnect={onClickOnConnect}
    />
  );
}

function EvoContainerAppsPlansSwiper({ isProductPage }: PlansSwiperProps) {
  const state = useTariffPlansState();
  const { connectProduct } = useSendConfigToConsole();

  const slides = state.plans[state.tariffPlansType].evolutionContainerApps.map(
    (plan) => {
      return (
        <EvoContainerAppsPlanCard
          key={plan.planId}
          plan={plan}
          price={plan.price[state.currentPricePeriod]}
          description={plan.description}
          onClickOnConnect={() =>
            connectProduct('evolutionContainerApps', plan)
          }
          currentPricePeriod={state.currentPricePeriod}
        />
      );
    },
  );

  return (
    <TariffSwiper
      slides={[
        ...slides,
        <CalcCard
          key="card-calculator"
          planIndex={slides.length}
          product="evolutionContainerApps"
        />,
      ]}
      isProduct={isProductPage}
    />
  );
}

export { EvoContainerAppsPlansSwiper };
