import { ButtonOutline } from '@snack-uikit/button';
import { Card } from '@snack-uikit/card';
import { Skeleton } from '@snack-uikit/skeleton';
import { Checkbox } from '@snack-uikit/toggles';
import { Typography } from '@snack-uikit/typography';
import cn from 'classnames';
import {
  CurrentPricePeriod,
  TariffsProductType,
} from 'src/components/TariffPlans/types';
import { PlansStatus } from 'src/components/TariffPlans/types';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { TagSpecial } from 'src/snack/components/TagSpecial';

import { camelToKebab } from '../../utils/camelToKebab';
import { PriceInCard } from '../PriceInCard';
import s from './PlanCard.module.scss';

type CharacteristicTypes = {
  title: string;
  value: string;
};

type PlanCardProps = {
  price: number;
  discountPrice?: number;
  currentPricePeriod: CurrentPricePeriod;
  planStatus?: PlansStatus;
  description?: string;
  characteristics: CharacteristicTypes[];
  onCheckboxClick?: () => void;
  checkBoxText?: string;
  checkBoxActive?: boolean;
  tooltipText?: string;
  tooltipHint?: string;
  connectButtonTitle?: string;
  onClickOnConnect?: () => void;
  productType: TariffsProductType;
  discountInPercent?: number;
  isFreeTier?: boolean;
  className?: string;
  freeTierTooltip?: string;
};

export function PlanCard({
  price,
  currentPricePeriod,
  planStatus,
  description,
  characteristics,
  onCheckboxClick,
  checkBoxText,
  checkBoxActive,
  tooltipText,
  tooltipHint,
  connectButtonTitle = 'Подключить',
  onClickOnConnect,
  productType,
  discountInPercent,
  isFreeTier,
  className,
  freeTierTooltip,
}: PlanCardProps) {
  const isShowFunctionalCheckbox =
    onCheckboxClick && checkBoxText && typeof checkBoxActive !== 'undefined';

  const isShowTooltip = tooltipText && tooltipHint;

  const isShowConnectButton = onClickOnConnect && productType;

  const isShowDiscount = typeof discountInPercent !== 'undefined';

  const discountValue =
    isShowDiscount && discountInPercent >= 100
      ? 'Бесплатно'
      : `-${discountInPercent}%`;

  const { clickAnalytics } = useAnalytics();

  return (
    <Card className={cn(s.card, className)}>
      <div className={s.content}>
        {isShowDiscount && (
          <div className={s.tagFree}>
            <TagSpecial
              className={s.tagFreeTier}
              label={discountValue}
              appearance="blue"
            />
          </div>
        )}

        {isFreeTier && (
          <div className={s.tagFree}>
            <TagSpecial
              label="Бесплатно"
              className={s.tagFreeTier}
              appearance="blue"
              tooltip={freeTierTooltip}
            />
          </div>
        )}

        <div className={s.contentMain}>
          <div className={s.cardPrice}>
            <Skeleton width={88} height={28} loading={planStatus === 'loading'}>
              <PriceInCard
                isFreeTier={isFreeTier}
                price={price}
                currentPricePeriod={currentPricePeriod}
                planStatus={planStatus}
                {...(isShowDiscount ? { discountInPercent } : {})}
              />
            </Skeleton>
            {description && (
              <Typography.SansBodyM className={s.cardDescription}>
                {description}
              </Typography.SansBodyM>
            )}
          </div>
          <div className={s.characteristics}>
            {characteristics.map(({ title, value }) => (
              <div key={title} className={s.cardRow}>
                <Typography.SansBodyM>{title}</Typography.SansBodyM>
                <Typography.SansTitleM className={s.char}>
                  {value}
                </Typography.SansTitleM>
              </div>
            ))}
          </div>
          {isShowFunctionalCheckbox && (
            <div className={s.cardCheck}>
              <Checkbox checked={checkBoxActive} onClick={onCheckboxClick} />
              <Typography.SansBodyL>{checkBoxText}</Typography.SansBodyL>
            </div>
          )}
          {isShowTooltip && (
            <TagSpecial
              label={tooltipText}
              appearance="blue"
              tooltip={tooltipHint}
              className={s.hint}
            />
          )}
        </div>
        {isShowConnectButton && (
          <ButtonOutline
            size="m"
            className={s.button}
            appearance="neutral"
            onClick={() => {
              onClickOnConnect();
              clickAnalytics({
                action: 'click',
                clickZone: 'body',
                clickElement: 'button',
                clickContent: connectButtonTitle,
                uniqueId: `${camelToKebab(productType)}-card-connect`,
                transitionType: 'inside-link',
              });
            }}
            label={connectButtonTitle}
          />
        )}
      </div>
    </Card>
  );
}
