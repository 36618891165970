import { ACTIONS } from 'src/components/TariffPlans/actions';
// import { useSendConfigToConsole } from 'src/components/TariffPlans/hooks/useSendConfigToConsole';`
import {
  useTariffPlansDispatch,
  useTariffPlansState,
} from 'src/components/TariffPlans/tariff-plans-context';
import {
  CurrentPricePeriod,
  PlanEvoCloudServerGpu,
  PlanIdCloudServerGpu,
  PlansSwiperProps,
} from 'src/components/TariffPlans/types';

import { CalcCard } from '../../components/CalcCard';
import { PlanCard } from '../../components/PlanCard';
import { TariffSwiper } from '../../components/TariffSwiper';
import { useUpdatePlanCardPrice } from '../../hooks/useUpdatePlanCardPrice';

interface PlanCardProps {
  planId: PlanIdCloudServerGpu;
  price: number;
  description: string;
  plan: PlanEvoCloudServerGpu;
  onClickOnConnect: () => void;
  currentPricePeriod: CurrentPricePeriod;
}

function EvoCloudServerGpuPlanCard({
  planId,
  price,
  plan,
  onClickOnConnect,
  currentPricePeriod,
}: PlanCardProps) {
  const dispatch = useTariffPlansDispatch();

  const { planState } = useUpdatePlanCardPrice({
    planId,
    priceActionType: ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_PRICE,
    statusActionType: ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_STATUS,
    productType: 'evolutionCloudServerGpu',
  });

  const characteristics = [
    {
      title: 'Графический процессор',
      value: `${plan.data.gpuType}`,
    },
    {
      title: 'Количество GPU, шт',
      value: `${plan.data.gpuCount}`,
    },
    {
      title: 'Количество vCPU, шт',
      value: `${plan.data.vCpuCoreCount}`,
    },
    {
      title: 'Объем RAM',
      value: `${plan.data.ramAmount}`,
    },
    {
      title: `Диск ${plan.data.evs.systemDisk.specification}`,
      value: `${plan.data.evs.systemDisk.diskSpace}`,
    },
  ];

  const onClickNetworkIsNeeded = () =>
    dispatch({
      type: ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_INPUTS,
      payload: {
        planId: plan.planId,
        data: {
          networkIsNeeded: !plan.data.networkIsNeeded,
        },
      },
    });

  return (
    <PlanCard
      productType="evolutionCloudServerGpu"
      planStatus={planState?.planStatus}
      currentPricePeriod={currentPricePeriod}
      price={price}
      characteristics={characteristics}
      onClickOnConnect={onClickOnConnect}
      onCheckboxClick={onClickNetworkIsNeeded}
      checkBoxText="Добавить IP-адрес"
      checkBoxActive={plan.data.networkIsNeeded}
    />
  );
}

function EvoCloudServerGpuPlansSwiper({ isProductPage }: PlansSwiperProps) {
  const state = useTariffPlansState();

  // const { connectProduct } = useSendConfigToConsole();

  const slides = state.plans[state.tariffPlansType].evolutionCloudServerGpu.map(
    (plan) => {
      return (
        <EvoCloudServerGpuPlanCard
          key={plan.planId}
          planId={plan.planId}
          plan={plan}
          price={plan.price[state.currentPricePeriod]}
          description={plan.description}
          // пока что оставляем так, тк нету продукта который можно было бы подключить в ЛК
          onClickOnConnect={() => {}}
          currentPricePeriod={state.currentPricePeriod}
        />
      );
    },
  );

  return (
    <TariffSwiper
      slides={[
        ...slides,
        <CalcCard
          key="card-calculator"
          planIndex={slides.length}
          product="evolutionCloudServerGpu"
        />,
      ]}
      isProduct={isProductPage}
    />
  );
}

export { EvoCloudServerGpuPlansSwiper };
