import { PlanEvoStorage } from 'src/components/TariffPlans/types';

import { price } from './data';

const evoStorage1: PlanEvoStorage = {
  planId: 'free-tier',
  planStatus: 'loading',
  price: price,
  productType: 'evolutionStorageS3FreeTier',
  description: 'Для медиаконтента, писем и документов',
  data: {
    productQuantity: 1,
    storageType: 'standart',
    storageSize: 15,
    apiPut: 100_000,
    apiList: 100_000,
    apiPost: 100_000,
    apiGet: 1_000_000,
    apiHead: 1_000_000,
    outgoingTraffic: 10_000,
  },
};

const evoStorage2: PlanEvoStorage = {
  planId: 'cold1000',
  planStatus: 'loading',
  price: price,
  productType: 'evolutionStorageS3',
  description: 'Для редкоиспользуемых данных, логов, отчетов',
  data: {
    productQuantity: 1,
    storageType: 'cold',
    storageSize: 1000,
    apiPut: 10000,
    apiList: 0,
    apiPost: 10000,
    apiGet: 0,
    apiHead: 0,
    outgoingTraffic: 0,
  },
};

const evoStorage3: PlanEvoStorage = {
  planId: 'ice3000',
  planStatus: 'loading',
  price: price,
  productType: 'evolutionStorageS3',
  description: 'Для бэкапов и архивов большого объема',
  data: {
    productQuantity: 1,
    storageType: 'ice',
    storageSize: 3000,
    apiPut: 10000,
    apiList: 0,
    apiPost: 60000,
    apiGet: 0,
    apiHead: 0,
    outgoingTraffic: 0,
  },
};

export { evoStorage1, evoStorage2, evoStorage3 };
