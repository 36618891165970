import { ACTIONS } from 'src/components/TariffPlans/actions';
import { useSendConfigToConsole } from 'src/components/TariffPlans/hooks/useSendConfigToConsole';
import { useTariffPlansState } from 'src/components/TariffPlans/tariff-plans-context';
import {
  CurrentPricePeriod,
  PlanEvoBareMetal,
  PlansSwiperProps,
} from 'src/components/TariffPlans/types';

import { CalcCard } from '../../components/CalcCard';
import { PlanCard } from '../../components/PlanCard';
import { TariffSwiper } from '../../components/TariffSwiper';
import { useUpdatePlanCardPrice } from '../../hooks/useUpdatePlanCardPrice';
import {
  getTotalCpu,
  getTotalDisk,
  getTotalRam,
} from '../../utils/evoBareMetalPlansSwiper';

interface PlanCardProps {
  plan: PlanEvoBareMetal;
  price: number;
  description: string;
  onClickOnConnect: () => void;
  currentPricePeriod: CurrentPricePeriod;
}

function EvoBareMetalPlanCard({
  plan,
  price,
  description,
  onClickOnConnect,
  currentPricePeriod,
}: PlanCardProps) {
  const {
    planId,
    data: { nameFlavour },
  } = plan;

  const { planState } = useUpdatePlanCardPrice({
    planId,
    priceActionType: ACTIONS.UPD_PLAN_EVOLUTION_BARE_METAL_PRICE,
    statusActionType: ACTIONS.UPD_PLAN_EVOLUTION_BARE_METAL_STATUS,
    productType: 'evolutionBareMetal',
  });

  const { 1: ram, 2: disk } = nameFlavour.split(';').map((el) => el.trim());

  const characteristics = [
    {
      title: 'Процессор (CPU)',
      value: getTotalCpu(planId),
    },
    {
      title: 'Оперативная память (RAM)',
      value: getTotalRam(ram),
    },
    {
      title: 'Диски',
      value: getTotalDisk(disk),
    },
    {
      title: 'IP-адрес',
      value: '1 шт',
    },
  ];

  return (
    <PlanCard
      productType="evolutionBareMetal"
      description={description}
      planStatus={planState?.planStatus}
      currentPricePeriod={currentPricePeriod}
      price={price}
      characteristics={characteristics}
      onClickOnConnect={onClickOnConnect}
    />
  );
}

function EvoBareMetalPlansSwiper({ isProductPage }: PlansSwiperProps) {
  const state = useTariffPlansState();
  const { connectProduct } = useSendConfigToConsole();

  const slides = state.plans[state.tariffPlansType].evolutionBareMetal.map(
    (plan) => {
      return (
        <EvoBareMetalPlanCard
          key={plan.planId}
          plan={plan}
          price={plan.price[state.currentPricePeriod]}
          description={plan.description}
          onClickOnConnect={() => connectProduct('evolutionBareMetal', plan)}
          currentPricePeriod={state.currentPricePeriod}
        />
      );
    },
  );

  return (
    <TariffSwiper
      slides={[
        ...slides,
        <CalcCard
          key="card-calculator"
          planIndex={slides.length}
          product="evolutionBareMetal"
        />,
      ]}
      isProduct={isProductPage}
    />
  );
}

export { EvoBareMetalPlansSwiper };
