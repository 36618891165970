import { PlanEvoPostgreSql } from 'src/components/TariffPlans/types';

import { price } from './data';

const evoPostgreSql1: PlanEvoPostgreSql = {
  planId: '05cpu1ram10gb',
  planStatus: 'loading',
  price: price,
  productType: 'evolutionPostgreSql',
  description: 'Минимальная конфигурация',
  data: {
    productQuantity: 1,
    clusterType: 'single',
    deploymentMode: 'standart',
    flavorConfig: {
      vCpu: '0.5',
      ram: '1',
    },
    systemDisk: {
      diskSpace: 10,
      specification: 'SSD',
    },
    bindingPublickIpAddress: false,
  },
};

const evoPostgreSql2: PlanEvoPostgreSql = {
  planId: '2cpu4ram40gb',
  planStatus: 'loading',
  price: price,
  productType: 'evolutionPostgreSql',
  description: 'Для несложных приложений',
  data: {
    productQuantity: 1,
    clusterType: 'single',
    deploymentMode: 'standart',
    flavorConfig: {
      vCpu: '2',
      ram: '4',
    },
    systemDisk: {
      diskSpace: 40,
      specification: 'SSD',
    },
    bindingPublickIpAddress: false,
  },
};

const evoPostgreSql3: PlanEvoPostgreSql = {
  planId: '4cpu16ram100gbå',
  planStatus: 'loading',
  price: price,
  productType: 'evolutionPostgreSql',
  description: 'Для высоконагруженных сервисов	',
  data: {
    productQuantity: 1,
    clusterType: 'single',
    deploymentMode: 'business',
    flavorConfig: {
      vCpu: '4',
      ram: '16',
    },
    systemDisk: {
      diskSpace: 100,
      specification: 'SSD',
    },
    bindingPublickIpAddress: false,
  },
};

export { evoPostgreSql1, evoPostgreSql2, evoPostgreSql3 };
