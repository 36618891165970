import { PlanEvoCloudServer } from 'src/components/TariffPlans/types';

import { price } from './data';

// Дефолтные планы evoCloudServer
const evoCloudServer1: PlanEvoCloudServer = {
  planId: 'free-tier',
  planStatus: 'loading',
  price: price,
  productType: 'evolutionCloudServerFreeTier',
  description: 'Для тестирования простых приложений и сайтов',
  data: {
    productQuantity: 1,
    guaranteedPart: '10',
    vCpuCoreCount: '2',
    ramAmount: '4',
    os: 'Fedora 38',
    evs: {
      additionalDisks: [],
      systemDisk: {
        specification: 'SSD',
        diskSpace: 30,
      },
    },
    networkIsNeeded: false,
    network: {
      ipQuantity: 1,
      bindingIpAddressesQuantity: 0,
      publicGatewayQuantity: 0,
      privateGatewayQuantity: 0,
    },
  },
};

const evoCloudServer2: PlanEvoCloudServer = {
  planId: '1vCPU1RAM10part',
  planStatus: 'loading',
  price: price,
  productType: 'evolutionCloudServer',
  description: 'Небольшая рабочая нагрузка',
  data: {
    productQuantity: 1,
    guaranteedPart: '10',
    vCpuCoreCount: '1',
    ramAmount: '1',
    os: 'Fedora 38',
    evs: {
      additionalDisks: [],
      systemDisk: {
        specification: 'SSD',
        diskSpace: 30,
      },
    },
    networkIsNeeded: false,
    network: {
      ipQuantity: 1,
      bindingIpAddressesQuantity: 0,
      publicGatewayQuantity: 0,
      privateGatewayQuantity: 0,
    },
  },
};

const evoCloudServer3: PlanEvoCloudServer = {
  planId: '1vCPU2RAM',
  planStatus: 'loading',
  price: price,
  productType: 'evolutionCloudServer',
  description: 'Стандартная рабочая нагрузка',
  data: {
    productQuantity: 1,
    guaranteedPart: '30',
    vCpuCoreCount: '1',
    ramAmount: '2',
    os: 'Fedora 38',
    evs: {
      additionalDisks: [],
      systemDisk: {
        specification: 'SSD',
        diskSpace: 30,
      },
    },
    networkIsNeeded: false,
    network: {
      ipQuantity: 1,
      bindingIpAddressesQuantity: 0,
      publicGatewayQuantity: 0,
      privateGatewayQuantity: 0,
    },
  },
};

export { evoCloudServer1, evoCloudServer2, evoCloudServer3 };
