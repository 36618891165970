import { WorkingHoursSpecification } from 'src/components/Calculator/types/evo';
import { PlanEvoContainerApps } from 'src/components/TariffPlans/types';

import { price } from './data';

const evoContainerApps1: PlanEvoContainerApps = {
  planId: '0_1vCpu256mb744hours',
  planStatus: 'loading',
  description: '',
  productType: 'evolutionContainerApps',
  price: price,
  data: {
    productQuantity: 1,
    config: '0.1 256',
    workingHours: 744,
    workingHoursSpecification: WorkingHoursSpecification.Month,
  },
};

const evoContainerApps2: PlanEvoContainerApps = {
  planId: '0_2vCpu512mb744hours',
  planStatus: 'loading',
  description: '',
  productType: 'evolutionContainerApps',
  price: price,
  data: {
    productQuantity: 1,
    config: '0.2 512',
    workingHours: 744,
    workingHoursSpecification: WorkingHoursSpecification.Month,
  },
};

const evoContainerApps3: PlanEvoContainerApps = {
  planId: '0_5vCpu1024mb744hours',
  planStatus: 'loading',
  description: '',
  productType: 'evolutionContainerApps',
  price: price,
  data: {
    productQuantity: 1,
    config: '0.5 1024',
    workingHours: 744,
    workingHoursSpecification: WorkingHoursSpecification.Month,
  },
};

export { evoContainerApps1, evoContainerApps2, evoContainerApps3 };
