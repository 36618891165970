import { dataTestAttrProp } from 'src/utils/dataTestAttrProp';

import s from './Disclaimer.module.scss';

export function Disclaimer() {
  return (
    <p className={s.text} {...dataTestAttrProp('disclaimer-block')}>
      Предварительный расчёт. Не является публичной офертой. Ресурсы
      предоставляются исключительно для тестирования. Не подразумевается их
      безвозмездное предоставление по смыслу пп.4 п.1 ст.575 ГК РФ
    </p>
  );
}
