import { ACTIONS } from 'src/components/TariffPlans/actions';
import { useSendConfigToConsole } from 'src/components/TariffPlans/hooks/useSendConfigToConsole';
import { useTariffPlansState } from 'src/components/TariffPlans/tariff-plans-context';
import {
  CurrentPricePeriod,
  PlanEvoKuber,
  PlanIdKuber,
  PlansSwiperProps,
} from 'src/components/TariffPlans/types';

import { CalcCard } from '../../components/CalcCard';
import { PlanCard } from '../../components/PlanCard';
import { TariffSwiper } from '../../components/TariffSwiper';
import { useUpdatePlanCardPrice } from '../../hooks/useUpdatePlanCardPrice';

interface PlanCardProps {
  planId: PlanIdKuber;
  price: number;
  description: string;
  bindingPublickIpAddress: PlanEvoKuber['data']['bindingPublickIpAddress'];
  masterNode: PlanEvoKuber['data']['masterNode'];
  workerNode: PlanEvoKuber['data']['workerNode'];
  onClickOnConnect: () => void;
  currentPricePeriod: CurrentPricePeriod;
}

function EvoKuberPlanCard({
  planId,
  price,
  description,
  masterNode,
  workerNode,
  onClickOnConnect,
  currentPricePeriod,
}: PlanCardProps) {
  const { planState } = useUpdatePlanCardPrice({
    planId,
    priceActionType: ACTIONS.UPD_PLAN_EVOLUTION_KUBERNETES_PRICE,
    statusActionType: ACTIONS.UPD_PLAN_EVOLUTION_KUBERNETES_STATUS,
    productType: 'evolutionKubernetes',
  });

  const uzelWorkerNode = workerNode.nodeCount === 1 ? 'узел' : 'узла';
  const uzelMasterNode = masterNode.nodeCount === '1' ? 'узел' : 'узла';

  const characteristics = [
    {
      title: 'Конфигурация мастер-узла',
      value: `${masterNode.nodeConfig.split(' ')[0]} vCPU ${
        masterNode.nodeConfig.split(' ')[1]
      } ГБ RAM`,
    },
    {
      title: 'Количество мастер-узлов',
      value: `${masterNode.nodeCount} ${uzelMasterNode}`,
    },
    {
      title: 'Конфигурация рабочего узла',
      value: `${workerNode.vCpuCount} vCPU ${workerNode.ramAmount} ГБ RAM`,
    },
    {
      title: 'Гарантированная доля vCPU',
      value: `${workerNode.guaranteedPart}% доля`,
    },
    {
      title: 'Количество рабочих узлов',
      value: `${workerNode.nodeCount} ${uzelWorkerNode}`,
    },
    {
      title: 'Диск SSD-NVMe',
      value: `${workerNode.diskSize} ГБ`,
    },
  ];

  return (
    <PlanCard
      productType="evolutionKubernetes"
      planStatus={planState?.planStatus}
      description={description}
      currentPricePeriod={currentPricePeriod}
      price={price}
      characteristics={characteristics}
      onClickOnConnect={onClickOnConnect}
      tooltipText="4 000 бонусных рублей"
      tooltipHint="Получите 4000 бонусных рублей на 60 дней за привязку банковской карты"
    />
  );
}

function EvoKuberPlansSwiper({ isProductPage }: PlansSwiperProps) {
  const state = useTariffPlansState();
  const { connectProduct } = useSendConfigToConsole();
  const slides = state.plans[state.tariffPlansType].evolutionKubernetes.map(
    (plan) => {
      return (
        <EvoKuberPlanCard
          key={plan.planId}
          planId={plan.planId}
          price={plan.price[state.currentPricePeriod]}
          description={plan.description}
          bindingPublickIpAddress={plan.data.bindingPublickIpAddress}
          masterNode={plan.data.masterNode}
          workerNode={plan.data.workerNode}
          onClickOnConnect={() => connectProduct('evolutionKubernetes', plan)}
          currentPricePeriod={state.currentPricePeriod}
        />
      );
    },
  );

  return (
    <TariffSwiper
      slides={[
        ...slides,
        <CalcCard
          key="card-calculator"
          planIndex={slides.length}
          product="evolutionKubernetes"
        />,
      ]}
      isProduct={isProductPage}
    />
  );
}

export { EvoKuberPlansSwiper };
