import { PlanEvoCloudServerGpu } from 'src/components/TariffPlans/types';

import { price } from './data';

const evoCloudServerGpu1: PlanEvoCloudServerGpu = {
  planId: '1gpu-4vcpu-64ram',
  planStatus: 'loading',
  price: price,
  productType: 'evolutionCloudServerGpu',
  description: '',
  data: {
    productQuantity: 1,
    gpuCount: '1',
    vCpuCoreCount: '4',
    ramAmount: '64',
    os: 'Ubuntu 22.04',
    gpuType: 'GPU NVIDIA® Tesla® V100',
    evs: {
      additionalDisks: [],
      systemDisk: {
        specification: 'SSD',
        diskSpace: 50,
      },
    },
    networkIsNeeded: false,
    network: {
      ipQuantity: 1,
      bindingIpAddressesQuantity: 0,
      publicGatewayQuantity: 0,
      privateGatewayQuantity: 0,
    },
  },
};

const evoCloudServerGpu2: PlanEvoCloudServerGpu = {
  planId: '4gpu-16vcpu-256ram',
  planStatus: 'loading',
  price: price,
  productType: 'evolutionCloudServerGpu',
  description: '',
  data: {
    productQuantity: 1,
    gpuCount: '2',
    vCpuCoreCount: '8',
    ramAmount: '128',
    os: 'Ubuntu 22.04',
    gpuType: 'GPU NVIDIA® Tesla® V100',
    evs: {
      additionalDisks: [],
      systemDisk: {
        specification: 'SSD',
        diskSpace: 50,
      },
    },
    networkIsNeeded: false,
    network: {
      ipQuantity: 1,
      bindingIpAddressesQuantity: 0,
      publicGatewayQuantity: 0,
      privateGatewayQuantity: 0,
    },
  },
};

const evoCloudServerGpu3: PlanEvoCloudServerGpu = {
  planId: '16gpu-64vcpu-1024ram',
  planStatus: 'loading',
  price: price,
  productType: 'evolutionCloudServerGpu',
  description: '',
  data: {
    productQuantity: 1,
    gpuCount: '4',
    vCpuCoreCount: '16',
    ramAmount: '256',
    os: 'Ubuntu 22.04',
    gpuType: 'GPU NVIDIA® Tesla® V100',
    evs: {
      additionalDisks: [],
      systemDisk: {
        specification: 'SSD',
        diskSpace: 50,
      },
    },
    networkIsNeeded: false,
    network: {
      ipQuantity: 1,
      bindingIpAddressesQuantity: 0,
      publicGatewayQuantity: 0,
      privateGatewayQuantity: 0,
    },
  },
};

export { evoCloudServerGpu1, evoCloudServerGpu2, evoCloudServerGpu3 };
