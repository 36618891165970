import { PlanEvoKuberLandingPage } from 'src/components/TariffPlans/types';

import { price } from './data';

const evoKuber1: PlanEvoKuberLandingPage = {
  planId: 'part10mn1c24wn1c24',
  planStatus: 'loading',
  price: price,
  productType: 'evolutionKubernetes',
  description: 'Минимальная рабочая нагрузка',
  data: {
    productQuantity: 1,
    workerNodeNeeded: true,
    masterNode: {
      nodeConfig: '2 4',
      nodeCount: '1',
    },
    workerNode: {
      guaranteedPart: '10',
      vCpuCount: '2',
      ramAmount: '4',
      nodeCount: 1,
      diskSize: 10,
    },
    bindingPublickIpAddress: true,
  },
};

const evoKuber2: PlanEvoKuberLandingPage = {
  planId: 'part10mn1c24wn1c48',
  planStatus: 'loading',
  price: price,
  productType: 'evolutionKubernetes',
  description: 'Небольшие приложения без высоких требований',
  data: {
    productQuantity: 1,
    workerNodeNeeded: true,
    masterNode: {
      nodeConfig: '2 4',
      nodeCount: '1',
    },
    workerNode: {
      guaranteedPart: '10',
      vCpuCount: '4',
      ramAmount: '8',
      nodeCount: 1,
      diskSize: 10,
    },
    bindingPublickIpAddress: true,
  },
};

const evoKuber3: PlanEvoKuberLandingPage = {
  planId: 'part30mn1c24wn1c24',
  planStatus: 'loading',
  price: price,
  productType: 'evolutionKubernetes',
  description: 'Стандартная рабочая нагрузка',
  data: {
    productQuantity: 1,
    workerNodeNeeded: true,
    masterNode: {
      nodeConfig: '2 4',
      nodeCount: '1',
    },
    workerNode: {
      guaranteedPart: '30',
      vCpuCount: '2',
      ramAmount: '4',
      nodeCount: 1,
      diskSize: 10,
    },
    bindingPublickIpAddress: true,
  },
};

const evoKuber4: PlanEvoKuberLandingPage = {
  planId: 'part30mn3c24wn3c24',
  planStatus: 'success',
  price: price,
  productType: 'evolutionKubernetes',
  description: 'Полная отказоустойчивая конфигурация',
  data: {
    productQuantity: 1,
    workerNodeNeeded: true,
    masterNode: {
      nodeConfig: '2 4',
      nodeCount: '3',
    },
    workerNode: {
      guaranteedPart: '100',
      vCpuCount: '2',
      ramAmount: '4',
      nodeCount: 2,
      diskSize: 16,
    },
    bindingPublickIpAddress: true,
  },
};

export { evoKuber1, evoKuber2, evoKuber3, evoKuber4 };
