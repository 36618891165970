import { Product } from 'src/components/Calculator/types';
import {
  Price,
  TariffsProduct,
  TariffsProductType,
} from 'src/components/TariffPlans/types';
import { backendHost } from 'src/global';
import useSWR from 'swr';

function productNameToKebab(productName: TariffsProductType) {
  const regex = /[A-Z]+(?![a-z])|[A-Z]/g;
  return productName.replaceAll(
    regex,
    ($, ofs) => (ofs ? '-' : '') + $.toLowerCase(),
  );
}

interface PriceBeReturn {
  success: boolean;
  data: {
    price: Price;
  };
}

async function fetcher<T extends TariffsProductType>({
  productType,
  productData,
}: {
  productType: TariffsProductType;
  productData: Product<T>['data'] | undefined;
}) {
  // Дата может быть пустой так как в /MainView/BottomBlock/index.tsx хук useUpdProductPriceInCheck запускается даже при пустой корзине, и если корзина пустая то productData не будет.
  const productTypeKebab = productNameToKebab(productType);

  const beUrlV2 = '/calculator/v2/prices/';
  const url = backendHost + beUrlV2 + productTypeKebab;

  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify(productData),
  });
  if (!res.ok) throw new Error(`fetcher`);
  const responce = (await res.json()) as PriceBeReturn;
  if (!responce.success) throw new Error(`fetcher - success false`);
  return responce.data;
}

function useGetConfigPrice({
  productType,
  productData,
}: {
  productType: TariffsProductType;
  productData: Product<TariffsProduct>['data'] | undefined;
}) {
  return useSWR(
    {
      productType: productType,
      productData: productData,
    },
    fetcher,
  );
}

export { useGetConfigPrice };
