import merge from 'lodash.merge';
import { createContext, useContext, useReducer } from 'react';
import { ACTIONS } from 'src/components/TariffPlans/actions';
import { tariffPlansInitialData } from 'src/components/TariffPlans/initialData';
import {
  Action,
  ActivePlansSet,
  Dispatch,
  PlanId,
  StateType,
  TariffPlansType,
  TariffsProduct,
} from 'src/components/TariffPlans/types';

const TariffPlansStateContext = createContext<StateType | undefined>(undefined);
const TariffPlansDispatchContext = createContext<Dispatch | undefined>(
  undefined,
);

function tariffPlansReducer(state: StateType, action: Action) {
  const newState: StateType = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case ACTIONS.UPD_CURRENT_PRICE_PERIOD: {
      newState.currentPricePeriod = action.payload;
      return { ...newState };
    }
    case ACTIONS.UPD_TARIFF_PLANS_TYPE: {
      newState.tariffPlansType = action.payload;
      return { ...newState };
    }
    case ACTIONS.UPD_ACTIVE_PLAN_INDEX: {
      newState.activePlanIndex = action.payload;
      return { ...newState };
    }
    case ACTIONS.UPD_ACTIVE_PLANS_SET: {
      newState.activePlansSet = action.payload;
      return { ...newState };
    }
    // evo cloud server
    case ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_PRICE: {
      const planId = action.payload.planId;
      const planToUpd = newState.plans[
        newState.tariffPlansType
      ].evolutionCloudServer.find((plan) => plan.planId === planId);

      if (planToUpd) planToUpd.price = action.payload.price;
      return { ...newState };
    }
    case ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_STATUS: {
      const planId = action.payload.planId;
      const planToUpd = newState.plans[
        newState.tariffPlansType
      ].evolutionCloudServer.find((plan) => plan.planId === planId);

      if (planToUpd) planToUpd.planStatus = action.payload.planStatus;
      return { ...newState };
    }
    case ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_INPUTS: {
      const planId = action.payload.planId;
      const planToUpd = newState.plans[
        newState.tariffPlansType
      ].evolutionCloudServer.find((plan) => plan.planId === planId);

      if (planToUpd) {
        planToUpd.data = merge(planToUpd.data, action.payload.data);
      }

      return { ...newState };
    }
    // evo kyber
    case ACTIONS.UPD_PLAN_EVOLUTION_KUBERNETES_PRICE: {
      const planId = action.payload.planId;
      const planToUpd = newState.plans[
        newState.tariffPlansType
      ].evolutionKubernetes.find((plan) => plan.planId === planId);

      if (planToUpd) planToUpd.price = action.payload.price;
      return { ...newState };
    }
    case ACTIONS.UPD_PLAN_EVOLUTION_KUBERNETES_STATUS: {
      const planId = action.payload.planId;
      const planToUpd = newState.plans[
        newState.tariffPlansType
      ].evolutionKubernetes.find((plan) => plan.planId === planId);

      if (planToUpd) planToUpd.planStatus = action.payload.planStatus;
      return { ...newState };
    }
    // evo kyber lp
    case ACTIONS.UPD_PLAN_EVOLUTION_KUBERNETES_LP_PRICE: {
      const planId = action.payload.planId;
      const planToUpd = newState.plans[
        newState.tariffPlansType
      ].evolutionKubernetesLandingPage.find((plan) => plan.planId === planId);

      if (planToUpd) planToUpd.price = action.payload.price;
      return { ...newState };
    }
    case ACTIONS.UPD_PLAN_EVOLUTION_KUBERNETES_LP_STATUS: {
      const planId = action.payload.planId;
      const planToUpd = newState.plans[
        newState.tariffPlansType
      ].evolutionKubernetesLandingPage.find((plan) => plan.planId === planId);

      if (planToUpd) planToUpd.planStatus = action.payload.planStatus;
      return { ...newState };
    }
    // evo s3
    case ACTIONS.UPD_PLAN_EVOLUTION_STORAGE_S3_PRICE: {
      const planId = action.payload.planId;
      const planToUpd = newState.plans[
        newState.tariffPlansType
      ].evolutionStorageS3.find((plan) => plan.planId === planId);

      if (planToUpd) planToUpd.price = action.payload.price;
      return { ...newState };
    }
    case ACTIONS.UPD_PLAN_EVOLUTION_STORAGE_S3_STATUS: {
      const planId = action.payload.planId;
      const planToUpd = newState.plans[
        newState.tariffPlansType
      ].evolutionStorageS3.find((plan) => plan.planId === planId);

      if (planToUpd) planToUpd.planStatus = action.payload.planStatus;
      return { ...newState };
    }
    // evo bare metal
    case ACTIONS.UPD_PLAN_EVOLUTION_BARE_METAL_PRICE: {
      const planId = action.payload.planId;
      const planToUpd = newState.plans[
        newState.tariffPlansType
      ].evolutionBareMetal.find((plan) => plan.planId === planId);

      if (planToUpd) planToUpd.price = action.payload.price;
      return { ...newState };
    }
    case ACTIONS.UPD_PLAN_EVOLUTION_BARE_METAL_STATUS: {
      const planId = action.payload.planId;
      const planToUpd = newState.plans[
        newState.tariffPlansType
      ].evolutionBareMetal.find((plan) => plan.planId === planId);

      if (planToUpd) planToUpd.planStatus = action.payload.planStatus;
      return { ...newState };
    }
    // evo cloud server gpu
    case ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_PRICE: {
      const planId = action.payload.planId;
      const planToUpd = newState.plans[
        newState.tariffPlansType
      ].evolutionCloudServerGpu.find((plan) => plan.planId === planId);

      if (planToUpd) planToUpd.price = action.payload.price;
      return { ...newState };
    }
    case ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_STATUS: {
      const planId = action.payload.planId;
      const planToUpd = newState.plans[
        newState.tariffPlansType
      ].evolutionCloudServerGpu.find((plan) => plan.planId === planId);

      if (planToUpd) planToUpd.planStatus = action.payload.planStatus;
      return { ...newState };
    }
    case ACTIONS.UPD_PLAN_EVOLUTION_CLOUD_SERVER_GPU_INPUTS: {
      const planId = action.payload.planId;
      const planToUpd = newState.plans[
        newState.tariffPlansType
      ].evolutionCloudServerGpu.find((plan) => plan.planId === planId);

      if (planToUpd) {
        planToUpd.data = merge(planToUpd.data, action.payload.data);
      }

      return { ...newState };
    }
    // evo postgre sql
    case ACTIONS.UPD_PLAN_EVOLUTION_POSTGRE_SQL_PRICE: {
      const planId = action.payload.planId;
      const planToUpd = newState.plans[
        newState.tariffPlansType
      ].evolutionPostgreSql.find((plan) => plan.planId === planId);

      if (planToUpd) planToUpd.price = action.payload.price;
      return { ...newState };
    }
    case ACTIONS.UPD_PLAN_EVOLUTION_POSTGRE_SQL_STATUS: {
      const planId = action.payload.planId;
      const planToUpd = newState.plans[
        newState.tariffPlansType
      ].evolutionPostgreSql.find((plan) => plan.planId === planId);

      if (planToUpd) planToUpd.planStatus = action.payload.planStatus;
      return { ...newState };
    }
    // evo container apps
    case ACTIONS.UPD_PLAN_EVOLUTION_CONTAINER_APPS_PRICE: {
      const planId = action.payload.planId;
      const planToUpd = newState.plans[
        newState.tariffPlansType
      ].evolutionContainerApps.find((plan) => plan.planId === planId);

      if (planToUpd) planToUpd.price = action.payload.price;
      return { ...newState };
    }
    case ACTIONS.UPD_PLAN_EVOLUTION_CONTAINER_APPS_STATUS: {
      const planId = action.payload.planId;
      const planToUpd = newState.plans[
        newState.tariffPlansType
      ].evolutionContainerApps.find((plan) => plan.planId === planId);

      if (planToUpd) planToUpd.planStatus = action.payload.planStatus;
      return { ...newState };
    }
    //
    default: {
      throw new Error('Unhandled action type: ' + action);
    }
  }
}

interface TariffPlansProviderProps {
  tariffPlansType: TariffPlansType;
  children: React.ReactNode;
}

function TariffPlansProvider({
  children,
  tariffPlansType,
}: TariffPlansProviderProps) {
  const initialState = tariffPlansInitialData[tariffPlansType];
  const [state, dispatch] = useReducer(tariffPlansReducer, initialState);

  return (
    <TariffPlansStateContext.Provider value={state}>
      <TariffPlansDispatchContext.Provider value={dispatch}>
        {children}
      </TariffPlansDispatchContext.Provider>
    </TariffPlansStateContext.Provider>
  );
}

/**
 * Хук который отдает стейт всего TariffPlans
 */
function useTariffPlansState(): StateType {
  const context = useContext(TariffPlansStateContext);
  if (context === undefined)
    throw new Error('useTariffPlansState must be used within a CalcProvider');

  return context;
}

function getActiveProductTypeByActivePlans(
  plansSet: ActivePlansSet,
): TariffsProduct {
  switch (plansSet) {
    case 'evoCloudServerMainPage':
      return 'evolutionCloudServer';
    case 'evoKuberMainPage':
      return 'evolutionKubernetes';
    case 'evoKuberLandingPage':
      return 'evolutionKubernetesLandingPage';
    case 'evoS3MainPage':
      return 'evolutionStorageS3';
    case 'evoBareMetalMainPage':
      return 'evolutionBareMetal';
    case 'evoCloudServerGpuMainPage':
      return 'evolutionCloudServerGpu';
    case 'evoPostgreSqlMainPage':
      return 'evolutionPostgreSql';
    case 'evoContainerAppsMainPage':
      return 'evolutionContainerApps';
  }
}

/**
 * Хук который отдает стейт конкретного плана по planId
 */
function useTariffPlansPlanState({ planId }: { planId: PlanId }) {
  const context = useContext(TariffPlansStateContext);
  if (context === undefined)
    throw new Error(
      'useTariffPlansState must be used within a TariffPlansProvider',
    );

  const productType = getActiveProductTypeByActivePlans(context.activePlansSet);

  const findPlan = context.plans[context.tariffPlansType][productType].find(
    (plan) => plan.planId === planId,
  );

  if (!findPlan) return undefined;
  return findPlan;
}

function useTariffPlansDispatch() {
  const context = useContext(TariffPlansDispatchContext);
  if (context === undefined)
    throw new Error(
      'useTariffPlansDispatch must be used within a CalcProvider',
    );

  return context;
}

export {
  TariffPlansProvider,
  useTariffPlansDispatch,
  useTariffPlansPlanState,
  useTariffPlansState,
};
