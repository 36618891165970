import { Carousel } from '@snack-uikit/carousel';

import s from './TariffSwiper.module.scss';

type TariffSwiperProps = {
  slides: JSX.Element[];
  isProduct?: boolean;
};

export const TariffSwiper = ({ slides, isProduct }: TariffSwiperProps) => {
  return (
    <>
      <div className={isProduct ? s.product : s.main}>
        <div className={s.desktop}>
          <Carousel
            showItems={isProduct ? 4 : 3}
            scrollBy={1}
            pagination={!isProduct}
            arrows={true}
            gap="32px"
          >
            {slides}
          </Carousel>
        </div>
        <div className={s.large}>
          <Carousel
            showItems={3}
            scrollBy={1}
            pagination
            arrows={true}
            gap="32px"
          >
            {slides}
          </Carousel>
        </div>
        <div className={s.medium}>
          <Carousel
            showItems={2}
            scrollBy={1}
            pagination
            arrows={true}
            gap="32px"
          >
            {slides}
          </Carousel>
        </div>
        <div className={s.mobile}>
          <Carousel
            showItems={1}
            scrollBy={1}
            pagination
            arrows={false}
            gap="32px"
          >
            {slides}
          </Carousel>
        </div>
      </div>
    </>
  );
};
