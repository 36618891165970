import { adapter } from 'src/components/Calculator/utils/adapter';
import { ACTIONS } from 'src/components/TariffPlans/actions';
import { useSendConfigToConsole } from 'src/components/TariffPlans/hooks/useSendConfigToConsole';
import { useTariffPlansState } from 'src/components/TariffPlans/tariff-plans-context';
import {
  CurrentPricePeriod,
  PlanEvoPostgreSql,
  PlanIdPostgreSql,
  PlansSwiperProps,
} from 'src/components/TariffPlans/types';

import { CalcCard } from '../../components/CalcCard';
import { PlanCard } from '../../components/PlanCard';
import { TariffSwiper } from '../../components/TariffSwiper';
import { useUpdatePlanCardPrice } from '../../hooks/useUpdatePlanCardPrice';

interface PlanCardProps {
  planId: PlanIdPostgreSql;
  plan: PlanEvoPostgreSql;
  price: number;
  description: string;
  onClickOnConnect: () => void;
  currentPricePeriod: CurrentPricePeriod;
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function EvoPostgreSqlPlanCard({
  planId,
  plan,
  price,
  description,
  onClickOnConnect,
  currentPricePeriod,
}: PlanCardProps) {
  const { planState } = useUpdatePlanCardPrice({
    planId,
    priceActionType: ACTIONS.UPD_PLAN_EVOLUTION_POSTGRE_SQL_PRICE,
    statusActionType: ACTIONS.UPD_PLAN_EVOLUTION_POSTGRE_SQL_STATUS,
    productType: 'evolutionPostgreSql',
  });

  const characteristics = [
    {
      title: 'Режим',
      value: `${adapter.renameEvoPostgreSqlDeploymentMode(
        plan.data.deploymentMode,
      )}`,
    },
    {
      title: 'Тип',
      value: `${capitalizeFirstLetter(plan.data.clusterType)}`,
    },
    {
      title: 'Количество vCPU, шт',
      value: `${plan.data.flavorConfig.vCpu}`,
    },
    {
      title: 'Объем RAM',
      value: `${plan.data.flavorConfig.ram} ГБ`,
    },
    {
      title: 'Диск',
      value: `${plan.data.systemDisk.diskSpace} ГБ ${plan.data.systemDisk.specification}`,
    },
  ];

  return (
    <PlanCard
      productType="evolutionPostgreSql"
      planStatus={planState?.planStatus}
      description={description}
      currentPricePeriod={currentPricePeriod}
      price={price}
      characteristics={characteristics}
      onClickOnConnect={onClickOnConnect}
      tooltipText="4 000 бонусных рублей"
      tooltipHint="Получите 4000 бонусных рублей на 60 дней за привязку банковской карты"
    />
  );
}

function EvoPostgreSqlPlansSwiper({ isProductPage }: PlansSwiperProps) {
  const state = useTariffPlansState();
  const { connectProduct } = useSendConfigToConsole();

  const slides = state.plans[state.tariffPlansType].evolutionPostgreSql.map(
    (plan) => {
      return (
        <EvoPostgreSqlPlanCard
          key={plan.planId}
          planId={plan.planId}
          plan={plan}
          price={plan.price[state.currentPricePeriod]}
          description={plan.description}
          onClickOnConnect={() => connectProduct('evolutionPostgreSql', plan)}
          currentPricePeriod={state.currentPricePeriod}
        />
      );
    },
  );

  return (
    <TariffSwiper
      slides={[
        ...slides,
        <CalcCard
          key="card-calculator"
          planIndex={slides.length}
          product="evolutionPostgreSql"
        />,
      ]}
      isProduct={isProductPage}
    />
  );
}

export { EvoPostgreSqlPlansSwiper };
